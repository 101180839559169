import React from 'react';
import styled from 'styled-components';

const MaintainServiceIntroPage = props => {
  return <Wrapper></Wrapper>;
};

const Wrapper = styled.div``;

export default MaintainServiceIntroPage;
